export function initialize_date_picker(wrapping_element) {

    const date_translations = JSON.parse(document.getElementById('date-picker-translations').innerHTML)
    var months = date_translations.month_names
    months.shift()

    const pickers = wrapping_element.querySelectorAll('input.date_picker')
    for (const picker of pickers) {
        if (!picker.getAttribute('date-picker-initialized')) {
            const format = picker.getAttribute('data-format')
            $(picker).Zebra_DatePicker({
                format: (format ? format : 'd.m.Y'),
                days_abbr: date_translations.abbr_day_names,
                months: months,
                show_select_today: date_translations.today_label,
                lang_clear_date: date_translations.clear_date_label
            })

            const wrap = picker.closest('.Zebra_DatePicker_Icon_Wrapper')
            wrap.style['width'] = '100%'
            wrap.setAttribute('hello','test')

            picker.setAttribute('date-picker-initialized', true)
        }
    }

}