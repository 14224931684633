import {Controller} from "@hotwired/stimulus"
import Cookies from "js-cookie";

export default class extends Controller {

    connect() {

        const init_icon = this.element.getElementsByClassName('initial-icon')[0]
        init_icon.addEventListener('click', () => this.open_bar())

        const close_button = this.element.getElementsByClassName('close-button')[0]
        close_button.addEventListener('click', () => this.close_bar())

    }

    open_bar() {

        this.element.classList.add('expanded')
        Cookies.set('right-bar-expanded', 'true', {sameSite: 'strict'})

    }

    close_bar() {

        this.element.classList.remove('expanded')
        Cookies.set('right-bar-expanded', 'false', {sameSite: 'strict'})

    }


}