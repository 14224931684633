import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="object-kind"
export default class extends Controller {
    static targets = ["delete", 'kindSelect', 'categorySelect', 'template'];
    static values = {hierarchy: Object}

    connect() {
    }

    delete() {
        this.deleteTarget.remove()
    }

    setHierarchy() {
        const kind = this.kindSelectTarget.value
        const hierarchy = this.hierarchyValue
        const options = hierarchy[kind]
        const categorySel = this.categorySelectTarget
        categorySel.length = 1
        Object.keys(options).forEach((id) => {
            categorySel.options[categorySel.options.length] = new Option(options[id], id)
        })
        categorySel.disabled = false
    }

    addCategory() {
        const kind = this.kindSelectTarget.value
        const category = this.categorySelectTarget.value
        const template = this.templateTarget
        const hierarchy = this.hierarchyValue
        const categorySel = this.categorySelectTarget
        const kindSel = this.kindSelectTarget

    //     add new entry in list of object kinds, use template
        template.insertAdjacentHTML(
            'beforebegin',
            template.innerHTML
                .replace(/__TIMESTAMP__/g, new Date().getTime().toString())
                .replace(/__CAT_VALUE__/g, category.toString())
                .replace(/__OBJECT_KIND__/g, kind)
                .replace(/__WORK_CATEGORY__/g, hierarchy[kind][category])
        )

        // reset selects
        categorySel.length = 1
        categorySel.disabled = true
        kindSel.selectedIndex = 0
    }
}
