import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['leftBimsWrapper']

    connect() {

    }

    changeBim() {
        const checked_inputs = this.leftBimsWrapperTarget.querySelectorAll('input')
        const right_part = this.element.querySelector('.right-part')
        let one_checked = false

        for (const checked_input of checked_inputs) {
            if (checked_input.checked) {
                one_checked = true
            }
        }

        const right_inputs = Array.from(right_part.querySelectorAll('.row.boolean'))

        for (const wrap of right_inputs) {
            if (one_checked) {
                wrap.classList.remove('disabled')
            } else {
                wrap.classList.add('disabled')
            }
            const inputs = wrap.querySelectorAll('input')
            for (const inp of inputs) {
                if (one_checked) {
                    inp.removeAttribute('disabled')
                } else {
                    inp.setAttribute('disabled', 'disabled')
                }
            }
        }
        const lod = document.getElementById('project_level_of_development')
        const vuc = document.getElementById('project_vdc_use_cases')
        if (one_checked) {
            lod.removeAttribute('disabled')
            lod.classList.remove('disabled')
            vuc.classList.remove('disabled')
            vuc.removeAttribute('disabled')
        } else {
            lod.setAttribute('disabled', 'disabled')
            vuc.setAttribute('disabled', 'disabled')
            lod.classList.add('disabled')
            vuc.classList.add('disabled')
        }
    }


}