import {Controller} from "@hotwired/stimulus"
import {mount} from "svelte";
import Modal from "@/javascript/components/modal.svelte";

// Confirm via Svelte component

// stimulus values:
// title
// text

// CODE EXAMPLE
// a{ data: { controller: 'confirm-modal', confirm_modal_text_value: confirm_text, confirm_modal_title_value: confirm_title }}

export default class extends Controller {

    static values = {title: String, text: String}

    connect() {
        this.element.addEventListener('click', (ev) => this.handleClick(ev))
    }

    handleClick(eve) {

        if(this.element.hasAttribute('disabled')) {
            eve.preventDefault()
            eve.stopImmediatePropagation()
            console.log('doing nothing because disabled')

        } else if(!this.element.getAttribute('data-confirm-perform-now')) {

            // Why did I do that on this way?
            // When a redirect_to (Rails-Controller) lives within a Ajax call, it cannot work.
            // For that reason, I set the attribute and click the link for a second time.

            eve.preventDefault()
            eve.stopImmediatePropagation()

            mount(Modal, {
                target: document.body,
                props: {
                    title: this.titleValue,
                    text: this.textValue,
                    action_button_label: window.gon.modal_delete,
                    action: () => this.handleAction()
                }
            })
        }
    }

    handleAction() {
        this.element.setAttribute('data-confirm-perform-now', true)
        this.element.click()
    }
}