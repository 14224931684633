import {Controller} from "@hotwired/stimulus"
import axios from "axios";
import getCSRFToken from "@shopify/csrf-token-fetcher";
import Modal from "@/javascript/components/modal.svelte";
import {mount} from 'svelte'

export default class extends Controller {
    static values = { id: Number }
    connect() {

        // initialize top-right-buttons

        const save_btn = document.getElementById('save-project-button')
        save_btn.addEventListener('click', () => this.save_form())

        const cancel_btn = document.getElementById('cancel-project-form-button')
        cancel_btn.addEventListener('click', () => this.cancel_form())

        const publish_btn = document.getElementById('publish-button')
        if (publish_btn) {
            publish_btn.addEventListener('click', () => this.toggle_publication_button(publish_btn))
        }

        // project data status
        const ds_input = document.getElementById('project_data_status_data_new_entry')
        ds_input.addEventListener('change', () => this.toggle_project_data_status())
        const ds_input2 = document.getElementById('project_data_status_data_after_completion')
        ds_input2.addEventListener('change', () => this.toggle_project_data_status())

        this.hide_project_step_first_label()
    }

    hide_project_step_first_label() {
        const wrapper = document.querySelector('.form-inputs')
        const lab = wrapper.querySelector('#first-step-label')
        const btn = wrapper.querySelector('#add-project-steps a.add_fields')
        btn.addEventListener('click', () => {
            lab.classList.add('hide')
        })
    }

    save_form() {
        const submit_btn = document.getElementById('project-submit-button')
        submit_btn.click()
    }

    cancel_form() {
        const f = document.querySelector('#project-form')
        f.reset()
    }

    toggle_publication_button() {
        const form = this.element.querySelector('#project-form')
        let modal_props = { title: window.gon.publish_modal_title }


        if (document.querySelector('.project-publication-status.published')) {
            modal_props.text = window.gon.already_published
            modal_props.action_button_label = null

        } else if (form.classList.contains('dirty')) {
            modal_props.text = window.gon.dirty_form_could_not_publish
            modal_props.action_button_label = window.gon.modal_cancel

        } else {
            modal_props.text = window.gon.publish_modal_content
            modal_props.action_button_label = window.gon.publish_modal_button
            const id = this.idValue
            modal_props.action = () => this.publishProject(id)
        }

        mount(Modal, {
            target: document.body,
            props: modal_props
        })
    }

    publishProject(id) {
        axios.patch(`/projects/${id}/publish`, {authenticity_token: getCSRFToken()})
            .then(function (response) {
                console.log('axios-success')
                if (response.status === 200) {
                    const marker = document.querySelector('.base-data .project-publication-status')
                    marker.classList.remove('new_no_content')
                    marker.classList.add('published')
                    add_flash_notice(gon.published_successfully)
                } else {
                    add_flash_alert(gon.could_not_be_published)
                }
            }).catch(function (error) {
            console.log('axios-catch')
            if (error.response) {
                console.log('axios-catch1')
                add_flash_alert(`${gon.could_not_be_published}: ${error.response.data}`)
            } else {
                add_flash_alert(gon.could_not_be_published)
            }
        })
    }

    noop() {}

    toggle_project_data_status() {

        const wrapper = document.getElementsByClassName('project-data-status')[0]

        const ds_input = wrapper.querySelector('input#project_data_status_data_new_entry')

        const sel1 = wrapper.querySelector('select#project_data_status_new_progress')
        const sel2 = wrapper.querySelector('select#project_data_status_after_completion_progress')

        if (ds_input.checked) {
            sel1.removeAttribute('disabled')
            sel2.setAttribute('disabled', 'disabled')
        } else {
            sel1.setAttribute('disabled', 'disabled')
            sel2.removeAttribute('disabled')
        }

        if (!ds_input.checked) {
            if (sel2.value === 'data_ac_no_progress') {
                sel2.value = 'data_ac_in_progress'
            }
        }
        //console.log(sel2.value)

    }
}