import {Controller} from "@hotwired/stimulus"
import Cookies from "js-cookie";

export default class extends Controller {

    filter_object = {}

    connect() {

        if (Cookies.get('projects-filter')) {
            try {
                this.filter_object = JSON.parse(Cookies.get('projects-filter'))
                this.set_filter_to_input()
            } catch (e) {
                console.error('projects-filter: no valid cookie:', Cookies.get('projects-filter'))
            }
        }

        const inputs = this.element.querySelectorAll('[name]')
        for (const input of inputs) {
            input.addEventListener('click', (e) => this.check_input(e))
        }
        console.log('START', this.filter_object, Cookies.get('projects-filter'))
    }

    check_input(ev) {

        const name = ev.target.getAttribute("name")
        this.filter_object['checked'] ||= []
        if (this.filter_object['checked'].includes(name)) {
            // remove name from checked
            this.filter_object['checked'].splice(this.filter_object['checked'].indexOf(name), 1)
            ev.target.classList.remove('checked')
        } else {
            this.filter_object['checked'].push(name)
            ev.target.classList.add('checked')
        }

        const f_str = JSON.stringify(this.filter_object)
        Cookies.set('projects-filter', f_str, {sameSite: 'strict'})
        this.set_filter_to_input()
        console.log(this.filter_object)

        const form= document.querySelector('form.search-field')
        form.submit()
    }

    set_filter_to_input() {
        const filter_input = document.querySelector('form.search-field input#query_filter')
        filter_input.value = JSON.stringify(this.filter_object)
    }

}