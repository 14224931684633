import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static values = { title: String }

    connect() {
        this.element.addEventListener('click', (ev) => this.first_click(ev))
    }

    first_click(eve) {

        const targ = eve.target

        if (!targ.hasAttribute('data-asked-and-remove-now')) {

            // events
            eve.preventDefault()
            eve.stopImmediatePropagation()

            const reveal = document.getElementById('confirm-action-reveal')
            const action_button = reveal.querySelector('#confirm_button')
            action_button.addEventListener('click', () => this.delete_row_action(reveal, eve))
            let show_dialog = true
            reveal.querySelector('h1').innerHTML = window.gon.cocoon_reveal_title

            // set text to reveal
            const col = targ.closest('.labels-column')
            if (col.parentNode.classList.contains('rough-planning')) {
                const number_input = col.querySelector('input.project_phase_number')
                const title_input = col.querySelector('input.project_phase_title')
                //if (number_input.value !== '') {
                    let body = `<p>${window.gon.cocoon_reveal_delete_phase_t1}</p><p><i>${window.gon.cocoon_reveal_delete_phase_t2}</i></p>`;
                    let _body = body.replace('[phase_number] [phase_name]', number_input.value + ' ' + title_input.value).replace('&nbsp;&nbsp;&nbsp;&nbsp;', ' ')
                    reveal.querySelector('p.body').innerHTML = _body
                // } else {
                //     show_dialog = false
                // }

            } else {
                // Resourcenplanung
                const opt = col.querySelector('select.cost_center').querySelector('option[selected="selected"]')
                let body = `<p>${this.titleValue}</p><p><i>${gon.cocoon_reveal_delete_resource_t2}</i></p>`;
                let _body
                if (opt) {
                    _body = body.replace('[phase_number] [phase_name]', opt.innerHTML).replace('&nbsp;&nbsp;&nbsp;&nbsp;', ' ')
                } else {
                    _body = body.replace('[phase_number] [phase_name]', '')
                }
                reveal.querySelector('p.body').innerHTML = _body
            }

            if (show_dialog) {

                // open the reveal
                $(reveal).foundation('open')
            }
        }

    }

    delete_row_action(reveal, first_event) {
        const first_element = first_event.target;
        const first_button = first_element.closest('a')
        console.log('first-element:', first_button)
        first_button.setAttribute('data-asked-and-remove-now', true)
        console.log('+')
        first_button.click()
        console.log('++')
        first_button.removeAttribute('data-asked-and-remove-now')
        console.log('+++')
        $(reveal).foundation('close')
    }
}