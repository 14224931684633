import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['input', 'template']

    connect() {

    }

    removeRow() {
        const wrap = event.target.closest('.selected-row')
        wrap.classList.add('hide')
        const del_inp = wrap.querySelector('input.destroy')
        del_inp.value = '1'
    }

    add() {
        const label = this.inputTarget.value
        if (label) {

            this.inputTarget.value = ''
            const ts = new Date().getTime().toString()

            const xmlString = this.templateTarget.innerHTML
                .replaceAll(/__TIMESTAMP__/g, ts)
                .replace(/__LABEL__/g, label)

            this.templateTarget.insertAdjacentHTML(
                'beforebegin',
                xmlString
            )
        }
    }

}