import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['chooseProject', 'template']

    connect() {

    }

    removeProject() {
        const wrap = event.target.closest('.selected-row')
        wrap.remove()
    }

    selectProject() {
        const select = this.chooseProjectTarget
        const value = select.value
        const label = select.querySelector(`option[value="${value}"]`).innerHTML
        select.value = ''
        const ts = new Date().getTime().toString()

        const xmlString = this.templateTarget.innerHTML
            .replaceAll(/__TIMESTAMP__/g, ts)
            .replace(/__LABEL__/g, label)
            .replace(/__VALUE__/g, value)

        this.templateTarget.insertAdjacentHTML(
            'beforebegin',
            xmlString
        )

        const del_btn = this.element.querySelector(`#delete-project-button-${ts}`)
        console.log(del_btn)
        del_btn.addEventListener('click', () => this.removeProject())
        del_btn.setAttribute('kjh', ts)
    }

}