import {Controller} from "@hotwired/stimulus"
import {mount} from "svelte";
import Modal from "@/javascript/components/modal.svelte";

// properties on a.remove_fields:
// data-confirm-title
// data-confirm-text

// CODE EXAMPLE
// a{ data: { controller: 'confirm-action', confirm_text: confirm_text, confirm_title: confirm_title }}

export default class extends Controller {

    static values = {title: String, text: String}

    connect() {
        this.element.addEventListener('click', (ev) => this.handleClick(ev))
    }

    handleClick(eve) {

        if(!this.element.getAttribute('confirm-action2-controller-has-clicked-me')) {

            // Why did i do that on this way?
            // When a redirect_to (Rails-Controller) lives within a Ajax call, it cannot work.
            // For that reason, i set the attribute and click the link for a second time.

            eve.preventDefault()
            eve.stopImmediatePropagation()

            mount(Modal, {
                target: document.body,
                props: {
                    title: this.titleValue,
                    text: this.textValue,
                    action_button_label: window.gon.modal_delete,
                    action: () => this.handleAction()
                }
            })
        }
    }

    handleAction() {
        this.element.setAttribute('confirm-action2-controller-has-clicked-me', true)
        this.element.click()
    }
}