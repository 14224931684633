<script>

    import {onMount} from 'svelte';
    import xmark from '@/images/icons8-close.svg?raw'

    let { title, text, action_button_label, cancel_button_label, action } = $props()

    // Usage-example: /frontend/javascript/views/project/form-controller.js:60

    let overlay_tag, content_tag

    onMount(() => {
        content_tag.addEventListener('click', (ev) => handleClickContent(ev))
        overlay_tag.addEventListener('click', (ev) => handleClose(ev))
    })

    function handleClose() {
        console.log('removing overlay')
        overlay_tag.remove()
    }

    function handleClickContent(e) {
        e.stopPropagation()
    }

    function handleAction() {
        if (action) { action() }
        handleClose()
    }

</script>

<div class="modal-overlay" bind:this={overlay_tag}>
    <div class="modal-content" bind:this={content_tag}>

        <div class="header">
            <span class="title">{title || 'missing title'}</span>
            <button class="close-button" on:click={() => handleClose()}>{@html xmark}</button>
        </div>

        <div class="content">
            {#each text.split('<br>') as msg}
                <p>{msg}</p>
            {/each}
        </div>

        <div class="buttons">
            {#if action_button_label}
                <button class="button2 execute full-width" on:click={() => handleAction()}>{action_button_label}</button>
            {/if}
            {#if cancel_button_label}
                <button class="button2 abort" on:click={() => handleClose()}>{cancel_button_label}</button>
            {/if}
        </div>

    </div>
</div>