import {Controller} from "@hotwired/stimulus"
import {initialize_date_picker} from "@/javascript/libs/initialize_date_picker.js";

// initializing steps after

export default class extends Controller {

    connect() {

        const callback  = () => {
            initialize_date_picker(this.element)
        }

        const observer = new MutationObserver(callback)
        observer.observe(this.element, { attributes: true, childList: true, subtree: true })
    }


    initialize_nodes() {
        console.log('mutated!')
    }

    disconnect() {
        console.log('disconnected!')
    }
}