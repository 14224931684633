import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    static values = { project: Number }

    connect() {

        // initialize the wrapper

        const row = this.element;
        const wrapper = this.element.closest('.ressource-planning-wrapper')
        if (!wrapper.getAttribute('stimulus-initialized')) {
            if (wrapper.offsetWidth < row.offsetWidth) {
                wrapper.querySelector('#phases-body').style.width = row.offsetWidth + 'px'
                wrapper.querySelector('.resources-body').style.width = row.offsetWidth + 'px'
            }
            wrapper.setAttribute('stimulus-initialized', true)
        }

        // only resource-planning:
        const user_select = this.element.querySelector('.user_id')
        if (user_select) {
            user_select.addEventListener('change', () => this.onChangePerson(user_select))
        }
    }

    onChangePerson(select) {
        console.log('user_id', select.value)
        axios.get(`/management/projects/${this.projectValue}/edit_workload_member_default_function?human_resource_id=${select.value}`)
            .then((response) => this.setStaffFunctionDefault(response))
            .catch(function (error){alert(error)})
    }

    setStaffFunctionDefault(response) {
        console.log('axios-response', response.data)
        const sf_select = this.element.querySelector('select.staff_function_id')
        sf_select.value = response.data.staff_function_id
    }

}